import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'

const IndexPage = () => (
  <Layout>
    <SEO title="Home" keywords={[`kolja™️`, `koljatm`]}/>
    <h1>Hallo Welt</h1>
    <p>Ich bin Kolja und hier ist verschiedener Spielkram für mich zum
      Ausprobieren.</p>
  </Layout>
)

export default IndexPage
